import React, { useState } from "react";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Contact, Popup } from "../../../components/popup";
import { Metaverse } from "../../../data/data";
import OfferSlider from "../../../components/offer-slider";

const MetaverseDevelopment = () => {
  const [popupShow, setPopupShow] = useState(false);
  const [popupShowContact, setPopupShowContact] = useState(false);

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };

  const handlePopupModalContact = () => {
    setPopupShowContact(!popupShowContact);
  };
  return (
    <Layout>
      <div className="wrapper">
        <section className="serviceHeader metaverseSolutionsHeader">
          <div className="innerPageNavigation">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services/">Services</Link>
              </li>
              <li>
                <Link to="/services/metaverse-development-services/">Metaverse</Link>
              </li>
            </ul>
          </div>
          <div className="serviceContainer">
            <div className="heroContent">
              <h1>Metaverse</h1>
              <p>
                Trusted by over 2000 startups and other businesses. An agile shop
                for excellence in mobile application development.
              </p>
              <button className="btn heroBtn" onClick={handlePopupModalContact}>
                Get an estimate
              </button>
            </div>
            <div className="servicePartner">
              <ul>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/enterprise-solutions/">
                      Enterprise Solutions&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/data-solutions/">
                      Data Solutions&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/cloud-solutions/">
                      Cloud Solutions&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/qa-testing/">
                      QA & Testing&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/startup-development/">
                      Startup Development&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/game-development/">
                      Game Development&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="serviceDetailsSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="serviceDetailsImage">
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="cardgradient"
                        src="../../../images/servicedetails/service-details-2.svg"
                      />
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="mobile-cardgradient"
                        src="../../../images/servicedetails/service-details-2.svg"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceContent">
                      <p>
                        Every cloud-based business now requires a mobile
                        application, and{" "}
                        <strong>
                          Xminds can help you 'push the edge' in terms of
                          technological growth.
                        </strong>{" "}
                        Xminds has a great pool of highly competent developers
                        to build leading apps within tight launch windows since
                        they were at the forefront of growing technology
                        adoption.
                      </p>
                      <p className="m-0">
                        <strong>
                          We have assisted several partners to harness the
                          potential of mobile technology
                        </strong>{" "}
                        by choosing the ideal framework for platform portability
                        without sacrificing features such as accelerometer,
                        geolocation, and camera.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="solutionSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="row">
                <div className="col-lg-6">
                  <h1>
                    <span className="circle"></span>
                    <span className="titleName">
                      Why choose Xminds for mobile solutions?
                    </span>
                  </h1>
                  <div className="solutionPoint">
                    <div
                      className="valuePoint"
                      data-aos-delay="500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <h5>01</h5>
                      <p>Uses robust IDE’s to speed up programming</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="1000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <h5>02</h5>
                      <p>
                        Skilled engineers in mobile technologies such as Android
                        and iPhone
                      </p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="1500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <h5>03</h5>
                      <p>Specific UI design expertise for small screens</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="2000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <h5>04</h5>
                      <p>
                        Offers innovative data handling techniques given limited
                        device capability
                      </p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="2500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <h5>05</h5>
                      <p>
                        Has extensive mobile applications implementation
                        experience
                      </p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="3000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <h5>06</h5>
                      <p>Engineers skilled in performance benchmarking tools</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mobiledevelopmentBox"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="serviceofferSection" id="metaverse">
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>What we offer?
              </h1>
              <div className="testimonialService">
                <div className="serviceContainer">
                  <div className="authorRow">
                    <OfferSlider data={Metaverse} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contactSection serviceContactSeaction">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="contactRelative">
                <div className="contactTitle">
                  <div className="casestudySlider">
                    <h1>
                      <span className="circle"></span>
                      <span className="titleName">
                        We love to listen to your requirements
                      </span>
                    </h1>
                  </div>
                </div>

                <Contact
                  type="static"
                  handleChange={handlePopupModal}
                  key={[popupShow, popupShowContact]}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="ourofficeSection serviceOtherSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>Our other services
              </h1>
              <div className="serviceOtherContainer">
                <div className="row">
                  <div className="servicePartner">
                    <ul>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/enterprise-solutions/">
                            Enterprise Solutions&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/data-solutions/">
                            Data Solutions&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/cloud-solutions/">
                            Cloud Solutions&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/qa-testing/">
                            QA & Testing&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/metaverse-development/">
                            Metaverse&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/startup-development/">
                            Startup&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/game-development/">
                            Game Development&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
      <Popup
        show={popupShowContact}
        handleClose={handlePopupModalContact}
        body="contactForm"
      />
    </Layout>
  );
};
export const Head = () => (
  <Seo
    title="Metaverse Development Services | Build Your Own Virtual Universe | Xminds"
    description="Unlock the endless possibilities of the Metaverse with Xminds comprehensive development services. Build and explore your own virtual universe today with us!"
    url="https://www.xminds.com/services/metaverse-development-services/"
  />
);



export default MetaverseDevelopment;
